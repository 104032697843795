export const FTMessageFinished = () => {
  return (
    <div className='text-center'>
      <h2 className='fw-bold mb-4'>Transferência finalizada</h2>
      <div className='my-3'>
        <i className='bi bi-check-circle-fill text-success' style={{fontSize: '5rem'}}></i>
      </div>
      <p className='fs-5 text-dark fw-bold'>
        Quando a unidade de destino aceitar a transferência, o processo de pagamento do repasse será
        continuado e o cliente receberá o link de pagamento.
      </p>
    </div>
  )
}
