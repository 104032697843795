import Swal from 'sweetalert2'
import {KTIcon} from '../../../../_metronic/helpers'
import {formatDate} from '../../../../utils/dayJs/format'
import {formatMask, formatMonetaryToBr} from '../../../../utils/formatMonetaryToBr'
import {BillingDetailModal} from '../../../sales/components/BillingDetailModal'
import {BillingEditStatusModal} from '../../../sales/components/BillingEditStatusModal'
import {useBillingContext} from '../../../sales/core/billingProvider'
import {getPixFranchise, confirmPaymentReq} from '../../list/core/_requests'
import {useAuth} from '../../../../app/modules/auth'
import {useState} from 'react'

export const BillingListTransfer = ({transfer, showPix, showConfirmBtn}) => {
  const {billingForView, billingsEditStatus, setBillingForView, setBillingsEditStatus} =
    useBillingContext()

  const {currentFranchise} = useAuth()
  const badColor = [
    {
      name: 'Pendente',
      color: 'badge badge-warning',
    },
    {
      name: 'Pago',
      color: 'badge badge-success',
    },
    {
      name: 'Cancelado',
      color: 'badge badge-danger',
    },
    {
      name: 'Vencido',
      color: 'badge badge-warning',
    },
  ]

  const dataTransferValues = transfer?.additionalData
  const transferBilling = transfer?.originSale?.billings
  const dataTrasnferNewSale = transfer?.newSale?.billings

  const openPixModal = async () => {
    try {
      const data = await getPixFranchise(String(transfer?.destinationFranchise?.id))
      let errorText = '<h3>' + data + '</h3>'
      await Swal.fire({
        icon: 'info',
        title: 'Realize o pagamento para a seguinte chave PIX',
        html: errorText,
        showConfirmButton: true,
        confirmButtonText: 'Copiar',
        confirmButtonColor: '#3085d6',
        denyButtonColor: '#d33',
        showDenyButton: true,
        denyButtonText: 'OK',
        allowOutsideClick: false,
      }).then((response) => {
        if (response.isConfirmed) {
          const textPix = data
          navigator.clipboard.writeText(String(textPix))
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'PIX copiado com sucesso!',
            timer: 2000,
          })
        }
      })
    } catch (error: any) {
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error?.response?.data?.message,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const confirmPayment = async () => {
    await Swal.fire({
      title: 'Deseja confirmar o recebimento do pagamento ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await confirmPaymentReq(String(transfer?.id))
          await Swal.fire({
            icon: 'success',
            title: 'Pagamento confirmado!!!',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          window.location.reload()
        } catch (error: any) {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error?.response?.data?.message,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      }
    })
  }

  return (
    <>
      {transfer?.originFranchise?.id === currentFranchise?.id && (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Informações da Cobrança</h3>
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-120px'>Preço</th>
                    <th className='min-w-120px'>Forma de pagamento</th>
                    <th className='min-w-120px'>Dt de vencimento</th>
                    <th className='min-w-120px'>Dt de pagamento</th>
                    <th className='min-w-120px'>Parcelas</th>
                    <th className='min-w-120px'>Status</th>
                    <th className='min-w-120px'>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {transferBilling?.map((billing, index) => {
                    const paidInstallments = billing.installments.filter((value) => {
                      return value.status === 'Pago'
                    })

                    const badgeStatus = badColor.filter(
                      (badColor) => badColor.name === billing.status
                    )

                    return (
                      <tr key={index}>
                        <td>{formatMask(billing?.amount)}</td>
                        <td>{billing?.paymentOption.name}</td>
                        <td>{formatDate(billing?.createdAt)}</td>
                        <td></td>
                        <td>
                          {billing?.numberOfInstallments > 0
                            ? paidInstallments.length + 1 + ' / ' + billing?.numberOfInstallments
                            : 'À vista'}
                        </td>
                        <td>
                          <a className={badgeStatus[0].color}>{billing.status}</a>
                        </td>
                        <td className='text-start'>
                          {billing?.paymentOption.id === 2 || billing?.paymentOption.id === 3 ? (
                            <button
                              onClick={() => setBillingForView(billing)}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                              <KTIcon iconName='eye' className='fs-3' />
                            </button>
                          ) : (
                            ''
                          )}
                          {billing.status === 'Pendente' &&
                          billing.paymentOption?.franchise !== null ? (
                            <button
                              onClick={() => setBillingsEditStatus(billing)}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                              <KTIcon iconName='gear' className='fs-3' />
                            </button>
                          ) : (
                            ''
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {billingForView && <BillingDetailModal billing={billingForView} />}
              {billingsEditStatus && <BillingEditStatusModal billing={billingsEditStatus} />}
            </div>
          </div>
        </div>
      )}

      {transfer?.approvedBy !== null &&
        transfer?.destinationFranchise?.id === currentFranchise?.id && (
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Informações Cobrança</h3>
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-120px'>Preço</th>
                      <th className='min-w-120px'>Forma de pagamento</th>
                      <th className='min-w-120px'>Dt de vencimento</th>
                      <th className='min-w-120px'>Dt de pagamento</th>
                      <th className='min-w-120px'>Parcelas</th>
                      <th className='min-w-120px'>Status</th>
                      <th className='min-w-120px'>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataTrasnferNewSale?.map((billing, index) => {
                      const paidInstallments = billing.installments.filter((value) => {
                        return value.status === 'Pago'
                      })

                      const badgeStatus = badColor.filter(
                        (badColor) => badColor.name === billing.status
                      )

                      return (
                        <tr key={index}>
                          <td>{formatMask(billing.amount)}</td>
                          <td>{billing.paymentOption.name}</td>
                          <td>{formatDate(billing.createdAt)}</td>
                          <td></td>
                          <td>
                            {billing.numberOfInstallments > 0
                              ? paidInstallments.length + 1 + ' / ' + billing.numberOfInstallments
                              : 'À vista'}
                          </td>
                          <td>
                            <a className={badgeStatus[0].color}>{billing.status}</a>
                          </td>
                          <td className='text-start'>
                            {billing?.paymentOption.id === 2 || billing?.paymentOption.id === 3 ? (
                              <button
                                onClick={() => setBillingForView(billing)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              >
                                <KTIcon iconName='eye' className='fs-3' />
                              </button>
                            ) : (
                              ''
                            )}
                            {billing.status === 'Pendente' &&
                            billing.paymentOption?.franchise !== null ? (
                              <button
                                onClick={() => setBillingsEditStatus(billing)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              >
                                <KTIcon iconName='gear' className='fs-3' />
                              </button>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                {billingForView && <BillingDetailModal billing={billingForView} />}
                {billingsEditStatus && <BillingEditStatusModal billing={billingsEditStatus} />}
              </div>
            </div>
          </div>
        )}

      {transfer?.hasFranchisePayment && (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Informações Repasse Franquia</h3>
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-120px'>Preço</th>
                    <th className='min-w-120px'>Forma de pagamento</th>
                    <th className='min-w-120px'>Status</th>
                    <th className='min-w-120px'>Ações</th>
                    <th className='min-w-120px'>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {formatMonetaryToBr(
                        dataTransferValues?.transferValues?.franchiseTransferValue
                      )}
                    </td>
                    <td>PIX</td>
                    <td>
                      <a
                        className={
                          transfer?.franchisePaymentCompleted
                            ? 'badge badge-success'
                            : 'badge badge-warning'
                        }
                      >
                        {transfer?.franchisePaymentCompleted ? 'Aprovado' : 'Pendente'}
                      </a>
                    </td>
                    <td>
                      {' '}
                      {showPix && (
                        <>
                          <button
                            className='btn btn me-3 px-4'
                            style={{
                              fontSize: '1rem',
                              fontWeight: 'bold',
                              color: 'white',
                              backgroundColor: '#f1416c',
                            }}
                            onClick={openPixModal}
                          >
                            Ver pix
                          </button>
                        </>
                      )}
                    </td>
                    <td>
                      {showConfirmBtn && !transfer.franchisePaymentCompleted && (
                        <>
                          <button
                            className='btn btn me-3 px-4'
                            style={{
                              fontSize: '1rem',
                              fontWeight: 'bold',
                              color: 'white',
                              backgroundColor: '#f1416c',
                            }}
                            onClick={confirmPayment}
                          >
                            Confirmar Pagamento
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
