import {formatMonetaryToBr} from '../../../../utils/formatMonetaryToBr'

export const InfoItems = ({transfer}) => {
  return (
    <>
      <div className='card mb-5 mb-xl-5'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h2 className='fw-bolder m-0'>Informações do produto</h2>
          </div>
        </div>
        <div className='card-body pt-9 pb-0'>
          <div className='table-responsive'>
            <table
              className='table table-bordered table-hover table-row-bordered table-row-gray-100'
              style={{tableLayout: 'fixed', width: '100%'}}
            >
              <thead>
                <tr className='fw-bold text-muted text-center'>
                  <th style={{width: '10%'}}>Produto</th>
                  <th style={{}}>Sessões</th>
                  <th style={{whiteSpace: 'nowrap'}}>Realizadas</th>
                  <th style={{whiteSpace: 'nowrap'}}>Pendente</th>
                  <th style={{whiteSpace: 'nowrap'}}>Valor UN</th>
                  <th style={{whiteSpace: 'nowrap'}}>Total</th>
                  <th>Valor pendentes</th>
                  <th style={{whiteSpace: 'nowrap'}}>Deságio</th>
                  <th style={{whiteSpace: 'nowrap'}}>Repasse</th>
                </tr>
              </thead>
              <tbody>
                {transfer?.itemsTransferValues?.map((item) => (
                  <>
                    <tr>
                      <td className='align-middle text-start '>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          {item?.productName}
                        </span>
                      </td>
                      <td className='align-middle text-center text-nowrap'>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          {item?.sessions}
                        </span>
                      </td>
                      <td className='align-middle text-center text-nowrap'>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          {item?.sessionsRealized}
                        </span>
                      </td>
                      <td className='align-middle text-center text-nowrap'>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          {item?.remainingSessions}
                        </span>
                      </td>
                      <td className='align-middle text-center text-nowrap'>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          {formatMonetaryToBr(item?.sessionValue)}
                        </span>
                      </td>
                      <td className='align-middle text-center text-nowrap'>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          {formatMonetaryToBr(item?.totalValue)}
                        </span>
                      </td>
                      <td className='align-middle text-center text-nowrap'>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          {formatMonetaryToBr(item?.remainingSessionsValue)}
                        </span>
                      </td>
                      <td className='align-middle text-center text-nowrap'>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          {formatMonetaryToBr(item?.deductedValue)}
                        </span>
                      </td>
                      <td className='align-middle text-center text-nowrap'>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          {formatMonetaryToBr(item?.transferValue)}
                        </span>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
