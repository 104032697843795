import { FC, useEffect, useState } from "react"
import Select from 'react-select';
import { usePeople } from "../../coreGlobal/providers/PersonProvider";
import { KTIcon } from "../../_metronic/helpers";
import { BankRequest } from "../../coreGlobal/models/bank";
import { TooltipWrapper } from "../Tooltip";
import Swal from "sweetalert2";

type Props = {
  formik: any,
}
const BankDataForm: FC<Props> = ({ formik }) => {

  const { banks, getDataBanks } = usePeople()
  const [fieldBanks, setFieldBanks] = useState<BankRequest[]>([]);
  const [location, setLocation] = useState(false);

  useEffect(() => {
    const init = async () => {
      await getDataBanks()
    }
    init()

    setLocation(window.location.href.includes("franchises"))
  }, [])

  const addField = () => {
    setFieldBanks([...fieldBanks, { isMain: false, account: '', accountDv: '', agency: '', agencyDv: '', bank: 0, accountType: 0, pix: '' }])

    formik.setFieldValue(`bankData`, [...fieldBanks, { isMain: false, account: '', accountDv: '', agency: '', agencyDv: '', bank: 0, accountType: 0, pix: '' }])
  }

  const removeField = (key: number) => {
    if (fieldBanks.length > 1) {
      if (fieldBanks[key].isMain !== true) {
        const newArray = [...fieldBanks]
        newArray.splice(key, 1)
        setFieldBanks(newArray);
        formik.setFieldValue(`bankData`, newArray)
      } else {
        Swal.fire({
          title: "O cadastro precisa ter pelo menos um dado bancário como principal",
          icon: "question"
        });
      }
    }
  }

  useEffect(() => {
    const init = async () => {
      const bankData = formik.values.bankData?.length ?
        formik.values.bankData.map(field => ({
          id: field?.id || '',
          isMain: field?.isMain || false,
          account: field?.account || '',
          accountDv: field?.accountDv || '',
          agency: field?.agency || '',
          agencyDv: field?.agencyDv || '',
          bank: field?.bank || '',
          accountType: field?.accountType || 0,
          pix: field?.pix || ''
        })) :
        [{ id: '', isMain: false, account: '', accountDv: '', agency: '', agencyDv: '', bank: '', accountType: 0, pix: '' }];
      setFieldBanks(bankData);
    };
    init();
  }, [formik.values.bankData]);

  const handleCheckboxChange = (postion) => {

    if ((formik.values.bankData.length === 1 && formik?.values?.bankData[postion]?.isMain === true) || (formik?.values?.bankData[postion]?.pix.length === 0 && (formik?.values?.bankData[postion]?.accountType === 0 || formik?.values?.bankData[postion]?.accountType === '')) || formik?.values?.bankData[postion]?.isMain === true) {
      return
    }
    Swal.fire({
      title: 'Deseja tornar esse pagamento como principal?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        formik?.values?.bankData.map((values2, index) => {
          formik.setFieldValue(`bankData[${index}].isMain`, !values2.isMain)
        });
      }
    })

  }

  return (
    <div className="card mb-5 mb-xl-10 shadow-sm">
      <div className="card-header collapsible cursor-pointer rotate" data-bs-toggle="collapse" data-bs-target="#kt_bank_data_card_collapsible">
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Dados Bancarios:</h3>
        </div>
        <div className="card-toolbar rotate-180">
          <i className="ki-duotone ki-down fs-1"></i>
        </div>
      </div>
      <div id="kt_bank_data_card_collapsible" className="collapse">
        <div className="card-body">
          <table className="table table-row-dashed table-row-gray-300 gy-7">
            <tbody>
              {
                fieldBanks.map((field, index) => {
                  const bankValue = banks?.find((bank, index) => {
                    return Number(bank.value) == field.bank
                  })
                  return (
                    <tr key={index}>
                      <td>
                        <div className='row mb-6'>
                          <div className='row mb-6'>
                            <div className='column col-lg-6'>
                              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                <span>Dados Bancarios</span>
                              </label>
                              <div className='col-lg-10 fv-row'>
                                <select
                                  id={`accountType${index}`}
                                  className='form-select form-select-lg form-select-solid'
                                  {...formik.getFieldProps(`bankData[${index}].accountType`)}
                                  onChange={(e) => {
                                    const updatedFieldBanks = [...fieldBanks];
                                    updatedFieldBanks[index].accountType = Number(e.target.value);
                                    setFieldBanks(updatedFieldBanks);
                                    formik.setFieldValue(`bankData[${index}].accountType`, e.target.value)
                                  }}
                                >
                                  <option value="">Selecione o tipo de conta</option>
                                  <option value="1">Conta Corrente</option>
                                  <option value="2">Conta Poupança</option>
                                  <option value="3">Conta Conjunta</option>
                                  <option value="4">Conta Salário</option>
                                </select>
                              </div>
                            </div>
                            <div className='column col-lg-6'>
                              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                <span className={fieldBanks[index].accountType == 0 ? '' : 'required'}>Banco</span>
                              </label>
                              <div className='col-lg-12 fv-row'>
                                <Select
                                  id={`bank${index}`}
                                  className='react-select-styled react-select-solid'
                                  classNamePrefix='react-select'
                                  onChange={option => {
                                    const updatedFieldBanks = [...fieldBanks]
                                    updatedFieldBanks[index].bank = Number(option?.value)
                                    setFieldBanks(updatedFieldBanks);
                                    formik.setFieldValue(`bankData[${index}].bank`, String(option?.value))
                                  }}
                                  options={banks}
                                  value={bankValue}
                                  placeholder='Selecione o banco'
                                  isDisabled={fieldBanks[index].accountType != 0 ? false : true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <div className='column col-lg-3'>
                              <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                <span className={fieldBanks[index].accountType == 0 ? '' : 'required'}>Agência</span>
                              </label>
                              <div className='col-lg-8 fv-row'>
                                <input
                                  id={`agency${index}`}
                                  type='text'
                                  {...formik.getFieldProps(`bankData[${index}].agency`)}
                                  placeholder='Agência'
                                  // value={field.agency}
                                  className='form-control form-control-lg form-control-solid'
                                  onChange={(e) => {
                                    const updatedFieldBanks = [...fieldBanks];
                                    updatedFieldBanks[index].agency = e.target.value;
                                    setFieldBanks(updatedFieldBanks);
                                    formik.setFieldValue(`bankData[${index}].agency`, e.target.value)
                                  }}
                                  disabled={fieldBanks[index].accountType != 0 ? false : true}
                                />
                                {formik.touched?.bankData && formik.touched?.bankData[index] && formik.errors?.bankData && formik.errors?.bankData[index]?.agency ? (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {formik.errors?.bankData[index]?.agency}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className='column col-lg-3' >
                              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className={fieldBanks[index].accountType == 0 ? '' : 'required'}>Conta</span>
                              </label>
                              <div className='col-lg-8 fv-row'>
                                <input
                                  id={`account${index}`}
                                  type='text'
                                  {...formik.getFieldProps(`bankData[${index}].account`)}
                                  // value={field.account}
                                  className='form-control form-control-lg form-control-solid'
                                  placeholder='Digite sua conta'
                                  onChange={(e) => {
                                    const updatedFieldBanks = [...fieldBanks];
                                    updatedFieldBanks[index].account = e.target.value;
                                    setFieldBanks(updatedFieldBanks);
                                    formik.setFieldValue(`bankData[${index}].account`, e.target.value)
                                  }}
                                  disabled={fieldBanks[index].accountType != 0 ? false : true}
                                />
                                {formik.touched?.bankData && formik.touched?.bankData[index] && formik.errors?.bankData && formik.errors?.bankData[index]?.account ? (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {formik.errors?.bankData[index]?.account}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className='column col-lg-3'>
                              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                <span className={fieldBanks[index].accountType == 0 ? '' : 'required'}>Digito da conta</span>
                              </label>
                              <div className='col-lg-8 fv-row'>
                                <input
                                  id={`accountDv${index}`}
                                  type='text'
                                  {...formik.getFieldProps(`bankData[${index}].accountDv`)}
                                  className='form-control form-control-lg form-control-solid'
                                  placeholder='Digite o número da conta'
                                  onChange={(e) => {
                                    const updatedFieldBanks = [...fieldBanks];
                                    updatedFieldBanks[index].accountDv = e.target.value;
                                    setFieldBanks(updatedFieldBanks);
                                    formik.setFieldValue(`bankData[${index}].accountDv`, e.target.value)
                                  }}
                                  maxLength={1}
                                  disabled={fieldBanks[index].accountType != 0 ? false : true}
                                />
                                {formik.touched?.bankData && formik.touched?.bankData[index] && formik.errors?.bankData && formik.errors?.bankData[index]?.accountDv ? (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {formik.errors?.bankData[index]?.accountDv}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            {location &&
                              <div className='column col-lg-3'>
                                <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                  <span className=''>PIX
                                    <TooltipWrapper
                                      tooltipText={'Essa chave será utilizada somente para transferência entre franquias.'}
                                      tooltipPlacement={'top'}
                                    >
                                      <button className='btn btn-icon  btn-active-color-danger btn-sm' type="button">
                                        <KTIcon iconName='information' className='fs-3' />
                                      </button>
                                    </TooltipWrapper>
                                  </span>
                                </label>
                                <div className='col-lg-12 fv-row'>
                                  <input
                                    id={`pix${index}`}
                                    type='text'
                                    {...formik.getFieldProps(`bankData[${index}].pix`)}
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Digite sua chave PIX'
                                    onChange={(e) => {
                                      const updatedFieldBanks = [...fieldBanks];
                                      updatedFieldBanks[index].pix = e.target.value;
                                      setFieldBanks(updatedFieldBanks);
                                      formik.setFieldValue(`bankData[${index}].pix`, e.target.value)
                                    }}
                                  />
                                  {formik.touched?.bankData && formik.touched?.bankData[index] && formik.errors?.bankData && formik.errors?.bankData[index]?.pix ? (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        {formik.errors?.bankData[index]?.pix}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            }
                          </div>
                          <div className='row mb-3'>
                            <div className='column col-lg-4'>
                              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                <span className='required'>Principal:</span>
                              </label>
                              <div className='col-lg-12 fv-row'>
                                <div key={`isMain${index}`} className='form-check form-switch form-check-custom form-check-solid'>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`bankData[${index}].isMain`}
                                    checked={field.isMain}
                                    onChange={(e) => {
                                      handleCheckboxChange(index)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">
                        {index > 0 &&
                          <button
                            id={`${index}`}
                            onClick={(e) => { removeField(index) }}
                            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                            type="button"
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </button>
                        }
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <button onClick={(e) => { e.preventDefault(); addField(); }} id="add" className="btn btn-primary" >
            Adicionar Dados Bancários
          </button>
        </div>
      </div>
    </div>
  )
}
export default BankDataForm