import {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import {toAbsoluteUrl, KTIcon} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {PeopleProvider} from '../../../coreGlobal/providers/PersonProvider'

import {LoadingSpinner} from '../../../utils/loading/loadingSpinner'

import {FranchiseTransferCardProvider} from '../../clients/components/FranchiseTransfer/core/FranchiseTransferProvider'
import {ListSalesHistorySchedules} from '../../sales/components/ListSalesHistorySchedules'
import {RecurrentPaymentProvider} from '../../sales/components/modalRecurrentPayment/core/recurrentPaymentProvider'
import {BillingProvider} from '../../sales/core/billingProvider'
import {ContractProvider} from '../../sales/core/ContractProvider'
import {
  approveTransfer,
  confirmPaymentReq,
  getPixFranchise,
  getTransferById,
  rejectTransfer,
} from '../list/core/_requests'
import {formatMonetaryToBr} from '../../../utils/formatMonetaryToBr'

import '../../clients/components/FranchiseTransfer/Utils/css/radioButtons.css'
import {useAuth} from '../../../app/modules/auth'
import {InfoItems} from './components/infoItems'
import {AmountTransfer} from './components/AmountTransfer'
import {BillingListTransfer} from './components/BillingsListTransfer'
import {formatDate, formatDateTime} from '../../../utils/dayJs/format'
const peopleBreadcrumbs: Array<PageLink> = [
  {
    title: 'Pessoa',
    path: '/people',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function getAvatarImg(person) {
  if (person?.physicalPerson?.hideAvatar) {
    return 'avatar-neutro.svg'
  }
  switch (person?.physicalPerson?.gender) {
    case 'F':
      return 'avatar-feminino.svg'
    case 'M':
      return 'avatar-masculino.svg'
    default:
      return 'avatar-neutro.svg'
  }
}

const Show = () => {
  let {id} = useParams()
  const [loadingPage, setLoadingPage] = useState(false)
  const [transfer, setTransfer] = useState<any>()

  const {currentFranchise} = useAuth()
  const [confirmPaymentStt, setConfirmPaymentStt] = useState(false)
  const [showButtonAction, setShowButtonAction] = useState(false)
  const [showButtonPix, setShowButtonPix] = useState(false)
  const [showSttsBtn, setShowSttsBtn] = useState(false)

  const dataTransfer = transfer?.additionalData?.transferValues

  useEffect(() => {
    const init = async () => {
      setLoadingPage(true)
      try {
        const data = await getTransferById(String(id))
        setTransfer(data)
        setLoadingPage(false)
      } catch (error: any) {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message,
          showConfirmButton: false,
          allowOutsideClick: false,
        })
      }
    }
    init()
  }, [])

  const handleApproveTransfer = async (id) => {
    await Swal.fire({
      title: 'Deseja realizar a transferência ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingPage(true)
        try {
          await approveTransfer(String(id))
          setLoadingPage(false)
          await Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Transferência aprovada com sucesso!!!',
            showConfirmButton: false,
            allowOutsideClick: false,
            timer: 3000,
          })
          window.location.reload()
        } catch (error: any) {
          setLoadingPage(false)

          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      }
    })
  }

  const handleRejectTransfer = async (id) => {
    await Swal.fire({
      title: 'Deseja recusar a transferência ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingPage(true)
        try {
          await rejectTransfer(String(id))
          setLoadingPage(false)
          await Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Transferência recusada com sucesso!!!',
            showConfirmButton: false,
            allowOutsideClick: false,
            timer: 3000,
          })
          window.location.reload()
        } catch (error: any) {
          setLoadingPage(false)

          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      }
    })
  }

  useEffect(() => {
    if (transfer?.lastHistory?.status == 4 || transfer?.franchisePaymentCompleted) {
      setConfirmPaymentStt(false)
      setShowButtonAction(false)
      setShowButtonPix(false)
      if (transfer?.lastHistory?.status == 4) {
        setShowSttsBtn(true)
      }
      return
    }

    if (
      transfer?.lastHistory?.status == 3 ||
      (transfer?.lastHistory?.status == 2 && !transfer?.hasFranchisePayment)
    ) {
      setShowSttsBtn(true)
      setShowButtonAction(false)
      setShowButtonPix(false)
      setConfirmPaymentStt(false)

      return
    }

    if (transfer?.destinationFranchise?.id === currentFranchise?.id) {
      if (transfer?.lastHistory?.status == 2 && transfer?.hasFranchisePayment) {
        setConfirmPaymentStt(true)
        return
      }
      setShowButtonAction(true)
      return
    }

    if (transfer?.originFranchise?.id === currentFranchise?.id && transfer?.approvedBy !== null) {
      setShowButtonPix(true)
      return
    }
  }, [transfer])

  return (
    <>
      {loadingPage ? (
        <LoadingSpinner title={'Carregando Dados Pessoais'} />
      ) : (
        <>
          <div className='card mb-5 mb-xl-5'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                  <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                    <img
                      src={toAbsoluteUrl(`/media/avatars/${getAvatarImg(transfer)}`)}
                      alt='Metronic'
                    />
                  </div>
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-1'>
                        <a
                          href='#'
                          className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                        >
                          {transfer?.client?.id} - {transfer?.client?.name} - TRANSFERÊNCIA: {''}
                          {transfer?.id}
                        </a>
                        <a href='#'>
                          <KTIcon iconName='verify' className='fs-1 text-primary' />
                        </a>
                      </div>
                      <div className='d-flex align-items-center mb-1'>
                        <span className='text-gray-800 text-hover-primary fs-3 fw-bolder me-1'>
                          {formatDateTime(transfer?.createdAt)}{' '}
                        </span>
                      </div>
                      <div className='d-flex align-items-center mb-1'>
                        <span className='text-gray-800 text-hover-primary fs-3 fw-bolder me-1'>
                          {transfer?.originFranchise?.name}{' '}
                          <KTIcon iconName='arrow-right' className='fs-2 text-danger' />{' '}
                          {transfer?.destinationFranchise?.name}
                        </span>
                      </div>
                    </div>
                    {showButtonAction && (
                      <>
                        <div className='d-flex align-items-end mb-1'>
                          <button
                            className='btn me-3 px-4'
                            style={{
                              fontSize: '1rem',
                              fontWeight: 'bold',
                              color: 'white',
                              backgroundColor: '#f1416c',
                            }}
                            onClick={() => handleApproveTransfer(id)}
                          >
                            Aceitar
                          </button>
                          <button
                            className='btn px-4'
                            style={{
                              fontSize: '1rem',
                              fontWeight: 'bold',
                              color: '#6c757d',
                              backgroundColor: '#fff',
                              border: '1px solid',
                              borderRadius: '4px',
                              borderColor: '#dcdcdc',
                            }}
                            onClick={() => handleRejectTransfer(id)}
                          >
                            Recusar
                          </button>
                        </div>
                      </>
                    )}

                    {showSttsBtn && (
                      <>
                        <span
                          className={`btn btn-${
                            transfer?.lastHistory?.status == 2 || transfer?.lastHistory?.status == 3
                              ? 'success'
                              : 'danger'
                          } me-3 px-4`}
                          style={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                          }}
                        >
                          {transfer?.lastHistory?.statusName}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <button
                      className='nav-link active'
                      id='pills-items-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-amount'
                      type='button'
                      role='tab'
                      aria-controls='pills-amount'
                      aria-selected='true'
                    >
                      Valores
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-items-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-items'
                      type='button'
                      role='tab'
                      aria-controls='pills-items'
                      aria-selected='true'
                    >
                      Items da transferência
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-cobranca-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-cobranca'
                      type='button'
                      role='tab'
                      aria-controls='pills-cobranca'
                      aria-selected='true'
                    >
                      Historico Cobrança
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='tab-content' id='pills-tabContent'>
            <div
              className='tab-pane fade show active p-4 pt-0 pt-md-4'
              id='pills-amount'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              {<AmountTransfer transfer={transfer} />}
            </div>

            <div
              className='tab-pane fade  p-4 pt-0 pt-md-4'
              id='pills-items'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              {<InfoItems transfer={dataTransfer} />}
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-cobranca'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              {
                <BillingListTransfer
                  transfer={transfer}
                  showPix={showButtonPix}
                  showConfirmBtn={confirmPaymentStt}
                />
              }
            </div>
          </div>
        </>
      )}
    </>
  )
}

export const TransferShowPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={peopleBreadcrumbs}>Dados Transferência</PageTitle>
      <PeopleProvider>
        <ContractProvider>
          <BillingProvider>
            <FranchiseTransferCardProvider>
              <RecurrentPaymentProvider>
                <Show />
              </RecurrentPaymentProvider>
            </FranchiseTransferCardProvider>
          </BillingProvider>
        </ContractProvider>
      </PeopleProvider>
    </>
  )
}
