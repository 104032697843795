export const removeMaskTransfer = (value) => {
  return value.replace(/\D/g, '')
}

export const formatMonetaryToBrTransfer = (value) => {
  const numericValue = Number(value) / 100
  return numericValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
}

export const formatToDecimal = (value, decimalSeparator = '.') => {
  const numericValue = typeof value === 'string' ? Number(value) : value

  if (isNaN(numericValue)) {
    throw new Error('Valor inválido: forneça um número ou string numérica válida.')
  }

  const decimalValue = (numericValue / 100).toFixed(2)

  if (decimalSeparator === ',') {
    return decimalValue.replace('.', ',')
  }

  return decimalValue
}
